// Slideout.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Slideout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Slideout = ({ isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden'; 
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
      document.body.style.overflow = ''; 
    };
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
    // onClose();
    document.body.style.overflow = ''; 
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      overlayClassName="modal-overlay"
      className="modal-content"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className='popback'>
        <div className='closediv'>
      <FontAwesomeIcon icon={faTimes} className="closebutt"  onClick={handleClose} />
      </div>
        <div className='d-flex'>
      </div>
      <div className='container'> 
        <div className='row cr'>
        <div className='col-md-7 col-sm-12 '>
        <h2 className='pophead'>3rd Dada Saheb Phalke Award Winner</h2>
        <img src='./AB.webp' className='popupimgg ' alt=''/>
        </div>
        <div className='col-md-5 col-sm-12 mt-5 '>
          <h2 className='pophead2 mt-md-2'>Mr. Gaurav K Singh</h2>
        <h3 className='poptext'>won 3rd Dada Saheb Phalke Award &
        added another award to Womeki's collection.</h3>
       <a href='/BrandStory'> <button type="button" className="seemorebutt">See More</button></a>
        </div>
      </div>
      </div>
      </div>
      </Modal>
  );
};

export default Slideout;
