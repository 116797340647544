import React from 'react'
import data from "./Realestatecard2.json"
import "./Realestatecards.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Realestatecard2 = () => {
  return (
    <div className='realpagediv container'>
    {data.Section1.map((NewData,index) => {
      return(
        <>
        <div key={index} className="image-container">
          <img className='Cardrealestateimg' src={NewData.Image} alt="img" />
          <div className='overlay_hero'>
            <h2 className="realestatehead1">{NewData.Heading}</h2>
            <p className="realestatecontent1">{NewData.Content}</p>
            </div>
            <div className='hero_txt_div'>
              <p className='hero_text'>This visionary project, led by<b> Mr. Gaurav Tonger </b> & managed by the respected Womeki Investors Club</p>
            </div>
      
        </div>
      </>
      )
    })}

    <div className='container'>
   
    <div className='row'>
    <h4 className='project-title'>Project Featuring</h4>
    {data.Section2.map((NewData, index) => {
      return(
        <div key={index} className='col-12 feature_div'>
        <p className='feature_txt'>{NewData.Content}</p>
        </div>
      )
    })}
    </div>
    
    
    <div className='row' >
      <h4 className='project-title'>Special Features</h4>
      {data.Section3.map((NewData, index) => {
      return(
      <div className='col-lg-3 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
        <div className='special_div'>
<p className='feature_txt' >{NewData.Content}</p>
</div>
      </div>
        )
      })}
    </div>

    <div className='row'>
      <h4 className='project-title'>Project Offerings</h4>
      {data.Section4.map((NewData, index) => {
      return(
      <div className='col-12'>
        <div className='d-flex'>
        <FontAwesomeIcon className='check' icon={faCheck} /> 
<p className='offer_txt' > {NewData.Content}</p>
</div>
      </div>
        )
      })}
    </div>


    <div className='row'>
      <h4 className='project-title'>Key Benefits Provided</h4>
      {data.Section5.map((NewData, index) => {
      return(
        <>
      <div key={index} className='col-5'>
<img className='project_img' src={NewData.Image} alt='dd'/>
      </div>
      <div className='col-7 d-flex align-items-center justify-content-center '>
        <div className='project_txt_div'>
<p className='key_txt'>{NewData.Content}</p>
</div>
      </div>
      </>
        )
      })}

{data.Section6.map((NewData, index) => {
      return(
        <>
       <div className='col-7 d-flex align-items-center justify-content-center '>
        <div className='project_txt_div2'>
<p className='key_txt'>{NewData.Content}</p>
</div>
      </div>
      <div key={index} className='col-5'>
<img className='project_img' src={NewData.Image} alt='dd'/>
      </div>
      </>
        )
      })}

{data.Section7.map((NewData, index) => {
      return(
        <>
        <div key={index} className='col-5'>
<img className='project_img' src={NewData.Image} alt='dd'/>
      </div>
      <div className='col-7 d-flex align-items-center justify-content-center '>
        <div className='project_txt_div'>
<p className='key_txt'>{NewData.Content}</p>
</div>
      </div>
      </>
        )
      })}

{data.Section8.map((NewData, index) => {
      return(
        <>
      <div className='col-7 d-flex align-items-center justify-content-center '>
        <div className='project_txt_div2'>
<p className='key_txt'>{NewData.Content}</p>
</div>
      </div>
      <div key={index} className='col-5'>
<img className='project_img' src={NewData.Image} alt='dd'/>
      </div>
      </>
        )
      })}
    </div>

    <div className='row'>
      <h4 className='project-title'>Additional Information</h4>
    {data.Section9.map((NewData, index) => {
      return(
        <div key={index} className='col-12'>
        <p className='info_text'>{NewData.Content1}</p>
        <p className='info_text'>{NewData.Content2}</p>
        <p className='info_text'>{NewData.Content3}</p>
        </div>
      )
    })}
    </div>

    <div className='row'>
    <h4 className='project-title'>Client Review</h4>
    <div className='col-md-6 col-sm-12'>
      <div className='client_div'>
        <h4 className='client_text'>Happiness and satifaction</h4>
      </div>
    </div>
    <div className='col-md-6 col-sm-12'>
      <div className='client_div'>
      <h4 className='client_text'>Reliable and professional management</h4>
      </div>
    </div>
    <div className='col-sm-12 d-flex align-items-center justify-content-center'>
      <div className='client_div'>
      <h4 className='client_text'>Profitable and straightforward investment</h4>
      </div>
    </div>
    <div className='col-md-6 col-sm-12'>
      <div className='client_div'>
      <h4 className='client_text'>Perfect office space found</h4>
      </div>
    </div>
    <div className='col-md-6 col-sm-12'>
      <div className='client_div'>
      <h4 className='client_text'>Smooth and easy relocation</h4>
      </div>
    </div>
    </div>
    </div>

    

    </div>
  )
}

export default Realestatecard2
