import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faBars, faX  } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const location = useLocation();
  const [openIndex, setOpenIndex] = useState(null);
  // const [showLogo, setShowLogo] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const handleToggle = (index) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  
    // useEffect(() => {
    //   if (location.pathname === '/') {
        
    //     const logoTimeout = setTimeout(() => {
    //       setShowLogo(true);
    //     }, 2700);

    //     return () => clearTimeout(logoTimeout);
    //   } else {
       
    //     setShowLogo(true);
    //   }
    // }, [location.pathname]);

    useEffect(() => {
      if (isNavbarOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    }, [isNavbarOpen]);
  
  return (
    <div className="nav-div-main">
      <nav>
        <div className="wrapper">
        
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" onClick={() => setIsNavbarOpen(false)}/>
          <label htmlFor="menu-btn" className="btn menu-btn" onClick={() => setIsNavbarOpen(true)}>
          <FontAwesomeIcon className="bars-img" icon={faBars} />
          </label>
          <a href="/">
          <div> <img src="./grouplogo.png" className="navlogo" alt="dd"/></div></a>
          <ul className={`nav-links`}>
          <label htmlFor="close-btn" className="btn close-btn-logo">
          <img className="nav-logo "  src="./grouplogo.png" alt="ll"  />
            </label>
          <label htmlFor="close-btn" className="btn close-btn">
          <FontAwesomeIcon className="cross-icon" icon={faX} />
            </label>
            <a href="/">
              <li className="mobile-linknews">
              HOME </li></a>
        
              <li className="mobile-linknews" onClick={() => handleToggle(1)}>
              BUSINESS  <FontAwesomeIcon className="caret-img" icon={faCaretDown} /></li>
              {openIndex === 1 &&
              <div className="nav-dropdown">
                <div className="d-flex ">
                <a href="/business">
               <li className="desktop-droplinknews ">
                Business
               </li></a>
                <div className="dropbox_1">
                <a href="/BusinessEducation">
               <li className="mobile-droplinknews ">
                Education
               </li></a>
               <a href="/BusinessRealestate">
               <li className="mobile-droplinknews">
                Real Estate
               </li></a>
               <a href="/Co-Working">
               <li className="mobile-droplinknews">
                Co-working Spaces
               </li></a>
               <a href="/Builders">
               <li className="mobile-droplinknews">
               Builders
               </li></a>
               <a href="/Tech">
               <li className="mobile-droplinknews">
             Information Technology
               </li></a>
               <a href="/Holidayclub">
               <li className="mobile-droplinknews">
             Womeki Holiday club
               </li></a>
            </div>
            <div className="dropbox_2">
            <li className="mobile-droplinknews">
              <a href={"https://www.mcgopichand.org"} target="_blank" rel="noopener noreferrer"> Womeki's School
              </a></li>
               <li className="mobile-droplinknews">
               <a href={"https://www.wic.org.in"} target="_blank" rel="noopener noreferrer">
                Womeki Investors Club</a>
               </li>
               <li className="mobile-droplinknews">
               <a href={"https://nexuria.in/"} target="_blank" rel="noopener noreferrer">Nexuria</a>
               </li>
               <li className="mobile-droplinknews">
               <a href={"https://womekiinfra.com/"} target="_blank" rel="noopener noreferrer">  Womeki Infra</a>
               </li>
               <li className="mobile-droplinknews">
               <a href="/WomekiTechSolutions">
                          Womeki Tech Solution
                        </a>
               </li>
               <li className="mobile-droplinknews">
               <a href="/WomekiTechSolutions">
                          Hospitality
                        </a>
               </li>
            </div>
            </div>
          </div>}
    
             
               <li className="mobile-linknews" onClick={() => handleToggle(2)} >ABOUT <FontAwesomeIcon className="caret-img" icon={faCaretDown} /></li>
               {openIndex === 2 &&
               <div className=" nav-dropdown d-flex">

          <li className="desktop-droplinknews">
               <a href="/about">About</a>
               </li>
             <div>
               <li className="mobile-droplinknews">
               <a href="/OneVission">One Vision One Team</a>
               </li>
               <li className="mobile-droplinknews">
               <a href="/CeoMessage">CEO Message</a>
                </li>
                </div>
              </div>}
              <li className="mobile-linknews"  onClick={() => handleToggle(3)}>WOMEKI INTERNATIONAL <FontAwesomeIcon className="caret-img" icon={faCaretDown} />  </li>
              {openIndex === 3 &&
              <div className="nav-dropdown">
               <li className="mobile-droplinknews">
               <a href="/BusinessMeet">Business Meet</a>
               </li>
               <li className="mobile-droplinknews">
               <a href="/womekiInternational">Business Tour</a>
                </li>
               </div>}
             
            
              <a href="/blog">
              <li className="mobile-linknews" >
              BLOG </li></a>
              <a href="/Career">
           <li className="mobile-linknews">
              CAREER </li></a>
           
              <a href="/contact">
             <li className="mobile-linknews" onClick={() => handleToggle(6)}>
              CONNECT</li></a>
           
          </ul>
         
        </div>
      </nav>
    </div>
  );
};
export default Navbar;



