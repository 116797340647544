import React  from 'react';
import data from "./AboutUs.json";
import "./AboutUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF,  faLinkedinIn, faInstagram   } from "@fortawesome/free-brands-svg-icons";


const AboutUs = () => {

  return (
    <div className="aboutmaindiv  ">
      <div className="aboutlandingpage ">

          <div className="container" >
            <h1 className="aboutbghead1">Nurturing Youth</h1>
            <h2 className="aboutbghead2">Going Global</h2>
        
        </div>
      </div>
      <div className="container aboutcarddiv pt-md-5 pb-md-5">
            <div className="row pt-5 pb-5">
      {data.Section1.map((NewData) => {
        return ( 
          <>
           <h5 className="aboutcardhead pb-md-5">{NewData.Heading}</h5>
              <div className="col-md-7 col-sm-12">
                <p className="aboutcardcontent">{NewData.Content}</p>
              </div>
              <div className="col-md-5 col-sm-12">
                <img className="abtcardimg" src={NewData.Image} alt="abt" />
              </div>
              </>
        )
      })}
        </div>
          </div>
          <div className="container aboutceomaindiv mt-lg-5 mb-5">
            <div className="row">   
     {data.Section2.map((NewData, index) => {
        return (
          <>
              <div key={index} className="col-md-4 col-sm-12">
                <div className="ceo-img-div">
                  <img className="abtceoimg" src={NewData.Image} alt="ceo" />
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
              <p className="abtceoconts">Our Chairman</p>
              <p className="abtceoconts1">{NewData.Para}</p>
                <p className="abtceocont">{NewData.Content}</p>
                <div className="abtceo-social-media d-flex justify-content-between ">
                  <div className="social-circle-fb">
                <a href={NewData.fblink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="socialiconfb" icon={faFacebookF} /></a></div>
                <div className="social-circle-linkedin">
                    <a href={NewData.linkedinlink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="socialiconlinkedin" icon={faLinkedinIn} /></a></div>
                    <div className="social-circle-insta">
                    <a href={NewData.instalink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon  className="socialiconinsta"  icon={faInstagram} /></a></div> 
                </div>
              </div>
              </>
        )
      })}
        </div>
          </div>
          <div className="abtteambg">
        <div className="container ourteammain pt-5 pb-5">
        <div className="row ">
          <h1 className="teamtitle">One Vision One Team</h1>
          {data.Section3.slice(2, 5).map((NewData, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center">
              <div>
              <div className={`card ourteamcarddiv border-0`}>
                  <div className="card-inner">
                    <div className="card-front">
                      <img src={NewData.Image} className="abtteamimg" alt="kk" />
                      <div className="abtteam-text">
                        <div>
                        <h1 className="card-title abtteamhead">{NewData.Heading}</h1>
                        <p className="card-text abtteamcontent">{NewData.Content}</p>
                        </div>
                        </div>
                    </div>
                    <div className="card-back d-flex flex-column align-items-center justify-content-center">
                        <div className="abtceo-social-media1">
                          <h5 className=" abtteamhead">{NewData.Heading}</h5>
                          <p className=" abtteamcontent">{NewData.Text} </p>
                          <div className='d-flex justify-content-around'>
                          <a href={NewData.fblink} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="socialiconfb" icon={faFacebookF} />
                          </a>
                          <a href={NewData.linkedinlink} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="socialiconlinkedin" icon={faLinkedinIn} />
                          </a>
                          <a href={NewData.instalink} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="socialiconinsta" icon={faInstagram} />
                          </a>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
            )
          })}
        </div>
      </div>
      </div>
      <div className="butt00">
            <a href="/OneVission">
          <button className="abtbtn">See More</button>
          </a>
          </div>
     
      <div className=" abtvissiondiv">
      <div className="container pt-5">
              <div className="row">
        {data.Section4.map((NewData, index) => {
          return (
              <div key={index} className="col-md-6 p-3 col-sm-12">
                <div className="card border-0 p-5 visioncarddiv">
                <h5 className="abtvismishead">{NewData.Heading}</h5>
                <p className="abtvismiscont">{NewData.Content}</p>
              </div> 
              </div>
          )
        })}
         </div>
            </div>
      </div>
      <div className="aboutwhyUsdiv pt-5">
        <div className="abtwhyustitle">WHY WOMEKI?</div>
        <div className="container ">
          <div className="row">
          {data.Section5.map((NewData, index) => {
            return (
                <div key={index} className="col-lg-4 col-md-12  mt-5">
                  <div className="card img-container border-0">
                    <img className="abtwhyimg" src={NewData.Image} alt="why" />
                   
                    <p className="abtwhycrdtxt">{NewData.Text}</p>
                    </div>
                  </div>
             
          
            )
          })}
        </div>    
        </div>
      </div>
      <div className="container unifiedvismmain pt-5 pb-5">
        <div className="row">
          <h1 className="unifiedtitle pb-5">UNIFIED VISION</h1>
          {data.Section6.map((NewData, index) => {
            return (
              <div key={index} className="col-sm-12 ourteamcoldiv pb-5">
                <img className="unifiedvisimg" src={NewData.Image} alt="sm" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};
export default AboutUs;