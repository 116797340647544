import React from 'react'
import data from "./BusinessEducation.json";
import "./BusinessEducation.css";
const BusinessEducation = () => {
  return (
    <div className=' pt-5 pb-5' >
      <h3 className={` text-center BSEdiv`}>EDUCATION</h3>
    <div className='container mt-md-4 mb-4'>
         <div className='row bg-white'>
            {data.Education.map((NewData,index) => {
                return(
                    <div key={index} className={`col-lg-4 col-md-6 mt-5 d-flex align-items-center justify-content-center`}>
                      <div className={`card border-0  businesseducardbox `}>
                      <a className="alink" href={NewData.Link}>
                        <img className={`card-img-top businesscardimg`}  height={250} alt='pp' src={NewData.Image}/>
                        <div className='card-body  business-col-div '>
                        <h5 className={`card-title eduHead`}>{NewData.Heading}</h5>
                        <p className={`card-text eduText`}>{NewData.Content}</p>
                        </div>
                        </a>
                      </div>
                    </div>
                )
            })}
           
        </div>
     
    </div>
    <div className=' visitdiv'>
        <div className='container  d-flex justify-content-between align-items-center p-5'>
          <p className='visit-text'>Visit the Website for Details</p>
        <a href={'https://www.mcgopichand.org/ '}  target="_blank" rel="noopener noreferrer" >
      <button className='visitedubutton'>Visit Now</button></a>
      </div>
      </div>
    </div>
  )
}

export default BusinessEducation
